import React, {Component} from 'react';
import {
    ExtGridcolumn,
} from '@sencha/ext-react-modern';
import * as f from "../common/Funcs";
import ErrorBoundary from "../ErrorBoundary";

const getKey = (record, props) => {
    if (!record || !props) {
        return 'none' + Math.floor(Math.random() * 10000);
    }
    return `${(props?.column_name) ? props.column_name : ''}${record.internalId}`
};

//обработка логического липа - актуальность
export const ActiveFunc = (value, record, props) => {
    switch (value) {
        case true:
            return <div key={getKey(record, props)} className="active-column is-grid-button fa fa-check-circle-o"/>;
        case false:
            return <div key={getKey(record, props)} className="active-column is-grid-button fa fa-ban"/>;
        default:
            return '?';
    }
};

export default class GridField extends Component {
    static defaultProps = {
        column_name: null,
        align: 'left', maxWidth: null,
        width: null,
        minWidth: null,
        ignoreExport: false,
        ignorePrint: false
    }

    constructor(props) {
        super(props);
        this.state = {loaded: false};
    }

    isActive() {
        return (this.props.column_name.indexOf('active') > -1)
    }

    isRights() {
        return (this.props.column_name.indexOf('rights') > -1)
    }

    isGeom() {
        return (this.props.column_name.indexOf('geom') > -1)
    }

    isDate() {
        return (this.props.column_name.indexOf('date') > -1)
    }

    //колонка подписи. Проверка на колонку, проверка прав, значок
    titleFunc(value, record, props) {
        const context = this;
        context.title =
            <span className={'link'} key={getKey(record, props)}
                  onClick={() => context.props.grid.showEditPanel(context.props.grid)}>{value}</span>
        return context.title;
    }

    //для колонки редакции. Проверка на колонку, проверка прав, значок
    rndFunc(value, record, props) {
        const context = this;
        const editButton = context.editButton(value, record, props);
        //  const dropButton = context.dropButton(value);
//        return [editButton, dropButton];
        return [editButton];
    }

    //обработка логического типа
    boolFunc(value, record, props) {
        switch (value) {
            case true:
                return 'Да';
            case false:
                return 'Нет';
            default:
                return '?';
        }
        //    return <input type="checkbox" checked={Boolean(value)} disabled={true}/>
    }


    //обработка логического липа - состояние
    stateFunc(value, record, props) {
        switch (value) {
            case true:
                return <div key={getKey(record, props)} className="active-column is-grid-button fa fa-check-circle-o"/>;
            case false:
                return <div key={getKey(record, props)} className="active-column is-grid-button fa fa-times-circle-o"/>;
            case '':
                return <div key={getKey(record, props)}
                            className="active-column is-grid-button fa fa-question-circle-o"/>;
            default:
                return '?';
        }
        //    return <input type="checkbox" checked={Boolean(value)} disabled={true}/>
    }

    //обработка логического типа
    checkFunc(value, record, props) {
        const onChange = (event) => {
            if (window.IasConfig.devMode) console.debug(`checked `, event.target.checked);
            const p = props;
            record.data[props.column_name] = event.target.checked;
            record.data.check = event.target.checked;
            record.data.modified = true;
        };
        return <input key={getKey(record, props)} type="checkbox" defaultChecked={Boolean(value)} onClick={onChange}/>
    }

    //обработка логического типа - табличная связь
    relFunc(value, record, props) {
        const _onChange = (event) => {
            const cl = props.column_name;
            if (props.onChange) record.data[cl] = props.onChange(event.target.checked, record);
            else record.data[cl] = event.target.checked;
            setTimeout(() => event.target.checked = record.data[cl], 50);
        };
        return <input key={getKey(record, props)} type="checkbox" checked={Boolean(value)} onChange={_onChange}/>
    }

    //обработка типа даты
    dateFunc(value, record) {
        if (!value) return '';
        const d = new Date(value);
        return d.toLocaleDateString();
    }

    //обработка поля "путь"
    pathFunc(value, record, props) {
        if (value?.length)
            return <span key={getKey(record, props)} className={'is-grid-button-text link'}
                         onClick={() => {
                             window.open(`${window.IasConfig.homePath}files/${props.description.tableName}/${value}`)
                         }}>скачать</span>;
        else return '';
    }

    //обработка поля "геометрия"
    geomFunc(params, record, props) {
        const {value, context} = params;
        const content = [];
        const key = getKey(record, props);
        if (value) {
            let parent = context;
            const olMapFunc = () => {
                parent = parent.props.parent;
                return parent.olMap || parent.props.olMap;
            }
            let olMap = olMapFunc();
            if (!olMap) olMap = olMapFunc();
            if (!olMap) olMap = olMapFunc();
            if (!olMap) debugger;
            const features = [JSON.parse(value)];
            const gotoFunction = () => {
                if (window.IasConfig.devMode) console.debug(window.funcName(), arguments);
                olMap.gotoFeatures({context: olMap, features})
            };
            const selectFunction = () => {
                if (window.IasConfig.devMode) console.debug(window.funcName(), arguments);
                olMap.select({features, context: olMap})
            };
            content.push(<span key={`${key}1`} name={'gotoButton'} className={'geo-action location is-grid-button'}
                               onClick={gotoFunction}/>);
            content.push(<span key={`${key}2`} name={'selectButton'} className={'geo-action map-marker is-grid-button'}
                               onClick={selectFunction}/>);
        }
        return <div key={`${key}3`} className={'geo-action-panel'}> {content}</div>
    }

    //колонка редакции, кнопка редактирования или просмотра
    editButton(value, record, props) {
        const context = this;
        if (f.isEditRight(value))
            return <span key={getKey(record, props)} name={'editButton'} className={'is-grid-button fa fa-edit'}
                         onClick={() => context.props.grid.showEditPanel(context.props.grid)}/>
        else if (value == '') return '';
        else
            return <span key={getKey(record, props)} className={'is-grid-button fa fa-eye'}
                         onClick={() => context.props.grid.showEditPanel(context.props.grid)}/>
    }

    //для ЗМУ
    countFunc(params, record, props) {
        let {column_name, cell, prm} = params;
        const closed = (window.IasLoApp.state?.zmu?.plan_closed || (prm.readonly));
        const valSpan = <span key={getKey(record, props)} name={'count-value'} className={'count-value'}
                              ref={v => cell.valfield = v}>{record.data[column_name]}</span>;
        //изменение количества прохождений
        const changeFunc = (k) => {
            let val = record.data[column_name];
            val = val + k;
            if (val < 0) val = 0;
            record.data[column_name] = val;
            //смена цвета после смены количества, для ЗМУ
            if (prm.getColor) {
                prm.getColor(val);
            }
            if (prm.func) {
                const state = prm.func(record);
                if (!state) val = val - k;
                record.data[column_name] = val;
            }
            cell.valfield.innerText = val;
        };
        const key = getKey(record, props);
        return <div key={key + 'd'} disabled={closed}>
                <span key={key + 's'} name={'down'} key={'down'} className={'count-button x-fa fa-chevron-left'}
                      onClick={() => (!closed) ? changeFunc(-1) : void 0}/>
            {valSpan}
            <span name={'up'} key={key + 'up'} className={'count-button x-fa fa-chevron-right'}
                  onClick={() => (!closed) ? changeFunc(1) : void 0}/>
        </div>
    }

    getClass(props) {
        let cls = '';
        if (props.ignorePrint) cls += ' no-print';
        if (props.className) {
            cls += ' ' + props.className;
        }
        if (props.data_type == 'check' && cls.indexOf('align-center') < 0) {
            cls = 'align-center';
        } else {
            if (!(this.props.column_name.indexOf('rights') > -1) && (props.data_type == 'character varying' || props.data_type == ''))
                cls += 'copy-class';
        }
        return cls;
    }

    getRefData(context) {
        const c = context.props.columnDescription;
        if (c.is_list && c.source) {
            const params = {
                context: context,
                tableName: c.source.tableName,
                idField: c.source.idField,
                titleField: c.source.titleField,
                filterString: c.source.filterString(),
                callback: (result) => {
                    if (result.data && result.data.length)
                        //       context.setState({data: result.data.map(i => i.title), loaded: true});
                        context.setState({data: result.data.map(i => i.title), ref: result.data, loaded: true});
                    else
                        context.setState({data: [], loaded: true});
                }
            };
            f.getRefData(params);
        }
    }

    render() {
        const context = this;
        const props = context.props;
        const params = {...{}, ...props};
        //параметры ячеек
        const func = (params) => {
            let {value, record, type, prm} = params;
            switch (type) {
                case 'date':  //дата
                    return context.dateFunc(value, record, props);
                case 'rel':  //связь, логический тип
                    return context.relFunc(value, record, props);
                case 'bool':  // логический тип
                    if (props.column_name.indexOf('active') > -1)
                        return ActiveFunc(value, record, props);
                    else if (props.column_name.indexOf('state') > -1 || props.column_name.indexOf('test') > -1)
                        return context.stateFunc(value, record, props);
                    else
                        return context.boolFunc(value, record, props);
                case 'counting':  // счетчик
                    return context.countFunc(params, record, props);
                case 'check':  //отметка, логический тип
                    return context.checkFunc(value, record, props);
                case 'path':  //путь к файлу
                    return context.pathFunc(value, record, props);
                case 'rnd':  //колонка редакции и прав
                    return context.rndFunc(value, record, props);
                case 'title':  //обработка заголовка
                    return context.titleFunc(value, record, props);
                case 'geom':  //колонка геометрии
                    return context.geomFunc({value: value, context: context}, record, props);
                case 'color':  //раскраска по заданному полю
                    return (<div key={getKey(record, props)} style={{
                        'background': `rgba(${document.getRGB(record.data[props.color_field]).join(',')})`,
                        'height': '12px',
                        'width': '100%'
                    }}/>);
                case 'custom':
                    return prm.renderer(params);
                default:
                    return (value) ? value : '';
            }
        }
        switch (props.data_type) {
            case 'integer':
            case 'numeric':
                if (!params.maxWidth) params.maxWidth = 100;
                params.align = 'right';
                params.format = ''
                break;
            case 'boolean':
                params.maxWidth = 50;
                params.type = 'bool';
                params.align = 'center';
                break;
            case 'check':
                params.maxWidth = 50;
                params.type = 'check';
                params.align = 'center';
                break;
            case 'counting':
                params.type = 'counting';
                params.align = 'center';
                break;
            case "timestamp without time zone":
            case 'date':
                params.type = 'date';
                break;
            case 'path':
                params.maxWidth = 100;
                params.align = 'center';
                params.type = 'path';
                break;
            case 'color':
                params.maxWidth = 50;
                params.align = 'center';
                params.type = 'color';
                break;
            case 'custom':
                params.type = 'custom';
                break;
            default:
        }
        if (props.colored) {
            switch (props.colored) {
                case 'alert':
                    params.colorFunc = (result, record, cell) => {
                        const val = record.data[props.color_field];
                        if (val === false) {
                            cell.addCls('red');
                            cell.removeCls('yellow');
                        } else if (val === true) {
                            cell.removeCls('red');
                            cell.removeCls('yellow');
                        } else {
                            cell.removeCls('red');
                            cell.addCls('yellow');
                        }
                    }
                    break;
                case 'rgb':
                    params.colorFunc = (result, record, cell) => {
                        cell.setBodyStyle({'background': `rgba(${document.getRGB(record.data[props.color_field]).join(',')})`});
                    };
                    break;
                case 'record':
                    params.colorFunc = (result, record, cell) => {
                        cell.setBodyStyle({'background': `rgba(${(record.color || [0, 0, 0, 0]).join(',')})`});
                    };
                    break;
                case 'fontrecord':
                    params.colorFunc = (result, record, cell) => {
                        cell.setBodyStyle({'color': `rgba(${(record.color || [0, 0, 0, 1]).join(',')})`});
                    };
                    break;
            }

        }
        if (props.relation) {
            params.maxWidth = 50;
            params.align = 'center';
            params.type = 'rel';
            params.column_label = 'V';
        }
        if (context.isDate()) {
            if (!params.maxWidth) params.maxWidth = '100px';
            if (!params.minWidth) params.minWidth = '100px';
        }
        if (context.isGeom()) {
            params.type = 'geom';
            params.ignorePrint = true;
            params.align = 'center';
            params.maxWidth = '70px';
            params.minWidth = '70px';
        }
        //колонка редакции
        if (context.isRights()) {
            params.align = 'center';
            params.type = 'rnd';
            params.ignoreExport = true;
            params.ignorePrint = true;
            params.maxWidth = '25px';
            params.minWidth = '25px';
        }
        //колонка редакции
        if (context.isActive()) {
            params.align = 'center';
            params.maxWidth = '20px';
            params.minWidth = '20px';
        }
        let cls = context.getClass(props);
        let editor = null;
        if (context.props.columnDescription?.is_list) {
            const data = context.state?.data || [];
            editor = {
                xtype: 'selectfield',
                options: data
            }
        }
        if (props.data_type == 'check') {
            editor = {xtype: 'checkboxfield', bodyCls: 'x-body-align-center'}
        }
        //для поля заголовка - линк на карточку
        if (props.title) {
            params.type = 'title'
        }
//по умолчанию растягиваем
        return <ExtGridcolumn
            encodeHtml={false}
            disabled={!params.editable}
            flex={(params.flex) ? params.flex : {grow: 2}}
            docked={params.docked}
            ignoreExport={params.ignoreExport}
            ignorePrint={params.ignorePrint}
            cls={cls}
            align={params.align}
            width={params.width}
            minWidth={params.minWidth}
            maxWidth={params.maxWidth}
            bufferSize={0}
            text={params.column_label || f.locale(params.column_name)}
            dataIndex={params.column_name}
            key={params.column_name}
            editable={params.editable}
            editor={editor}
            filter={f.getGridFilter(params.data_type)}
            hidden={(params.relation) ? false : params.hidden}
            format={params.format}
            renderer={(value, record, dataIndex, cell) => {
                cell.addCls(cls);
                // let result = <span  key={getKey(record,props)} >{value}</span>;
                let result = value;
                if (['numeric', 'integer'].indexOf(params.data_type) > -1 && result) {
                    result = Number(result);
                }
                if (params.type) {
                    result = func({value, record, type: params.type, cell, column_name: dataIndex, prm: params});
                }
                if (params.getColor) {
                    record.color = params.getColor(value);
                }
                if (params.colored) {
                    params.colorFunc(result, record, cell);
                }
                if (cls.indexOf('copy-class') > -1) {
                    result = <div key={getKey(record, props) + 'd1'}>
                        {/*<div className="x-before-input-el"></div>*/}
                        <div key={getKey(record, props) + 'd2'} className={cls}>
                            {result}
                            <div key={getKey(record, props) + 'd3'} className="x-after-input-el" onClick={() => {
                                f.copyToClipboard(value);
                                return f.toast({
                                    title: 'скопированно',
                                    message: value,
                                    timeout: window.IasConfig.messageTimeout
                                });
                            }}/>
                        </div>
                    </div>
                }
                return result;
            }}
            summary={params.summary}
            summaryRenderer={params.summaryRenderer}
            exportStyle={{
                format: (['numeric', 'integer'].indexOf(params.data_type) > -1) ? 'General Number' : 'General',
                width: params.minWidth || params.maxWidth,
            }}
            exportRenderer={(value, record, dataIndex, cell) => {
                let val = value;
                if (val == true) val = 'Да';
                if (val == false) val = 'Нет';
                return val;
            }}
        />

    }
}