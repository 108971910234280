import FlashPanel from "../common/FlashPanel";
import FooterPanel from "../Panels/FooterPanel";
import Header from "../Panels/Header";
import InlineMenu from "../common/InlineMenu";
import LeftPanel from "../Panels/LeftPanel";
import DialogForm from "../common/DialogForm";
import RightPanel from "../Panels/RightPanel";
import TopPanel from "../Panels/TopPanel";
import HuntUsersPage from "../Pages/Office/HuntUsersPage";
import HuntUserPage from "../SinglePages/Office/HuntUserPage";
import AddressPage from "../SinglePages/Office/AddressPage";
import HuntEconsPage from "../Pages/Office/HuntEconsPage";
import HuntEconPage from "../SinglePages/Office/HuntEconPage";
import HuntLandsPage from "../Pages/Office/HuntLandsPage";
import HuntLandPage from "../SinglePages/Office/HuntLandPage";
import BankPropsPage from "../Pages/Office/BankPropsPage";
import ContactsPage from "../Pages/Office/ContactsPage";
import ContactPage from "../SinglePages/Office/ContactPage";
import ContactRelPage from "../SinglePages/Office/ContactRelPage";
import DocumentsPage from "../Pages/Office/DocumentsPage";
import DocumentPage from "../SinglePages/Office/DocumentPage";
import Index from "../Pages/TopLevel/IndexPage";
import AdminPage from "../Pages/TopLevel/AdminPage";
import MapPage from "../Pages/Map/MapPage";
import OfficePage from "../Pages/TopLevel/OfficePage";
import ResoursesPage from "../Pages/TopLevel/ResoursesPage";
import TestPage from "../Pages/TestPage";
import Login from "../Security/Login";
import Reset from "../Security/Reset";
import Url from "../Security/Url";
import UserPage from "../Security/UserPage";
import ProfilePage from "../Security/ProfilePage";
import ZmuProfilePage from "../Security/ZmuProfilePage";
import UserRolePage from "../Security/UserRolePage";
import UsersAdminPage from "../Pages/Admin/UsersAdminPage";
import LogPage from "../SinglePages/Admin/LogPage";
import NewPage from "../SinglePages/Admin/NewPage";
import LogsPage from "../Pages/Admin/LogsPage";
import SessionsPage from "../Pages/Admin/SessionsPage";
import NewsPage from "../Pages/Admin/NewsPage";
import StocksPage from "../Pages/Regbook/StocksPage";
import MappingsPage from "../Pages/Regbook/MappingsPage";
import DtpsPage from "../Pages/Regbook/DtpsPage";
import ViolationsPage from "../Pages/Regbook/ViolationsPage";
import MappingPage from "../SinglePages/Regbook/MappingPage";
import DtpPage from "../SinglePages/Regbook/DtpPage";
import ViolationPage from "../SinglePages/Regbook/ViolationPage";
import MonitoringsPage from "../Pages/Regbook/MonitoringsPage";
import ReportsPage from "../Pages/Regbook/ReportsPage";
import PermisionsPage from "../Pages/Regbook/PermisionsPage";
import ReestrsPage from "../Pages/Regbook/ReestrsPage";
import SpeciesPage from "../Pages/Regbook/SpeciesPage";
import SpecPage from "../SinglePages/Regbook/SpecPage";
import SpecSynPage from "../SinglePages/Regbook/SpecSynPage";
import SpecViewPage from "../SinglePages/Regbook/SpecViewPage";
import SpecRbPage from "../SinglePages/Regbook/SpecRbPage";
import SpecLitPage from "../SinglePages/Regbook/SpecLitPage";
import SpecStatusPage from "../SinglePages/Regbook/SpecStatusPage";
import StaffPage from "../SinglePages/Office/StaffPage";
import PickupPage from "../SinglePages/Office/PickupPage";
import EmailPage from "../SinglePages/Office/EmailPage";
import HuntDescPage from "../SinglePages/Office/HuntDescPage";
import HuntCodesPage from "../SinglePages/Office/HuntCodesPage";
import PhonePage from "../SinglePages/Office/PhonePage";
import ZonePage from "../SinglePages/Office/ZonePage";
import ReportPage from "../SinglePages/Regbook/ReportPage";
import ZmuGraphPage from "../Pages/ZMU/Analize/ZmuGraphPage";
import ZmuIndex from "../Pages/ZMU/ZmuIndex";
import ZmuAccPage from "../Pages/ZMU/Acc/ZmuAccPage";
import EaPage from "../SinglePages/ZMU/Plan/EaPage";
import EventPage from "../SinglePages/ZMU/Acc/EventPage";
import PassCommentPage from "../SinglePages/ZMU/Acc/PassCommentPage";
import PasEaPage from "../SinglePages/ZMU/Acc/PasEaPage";
import RoutePage from "../SinglePages/ZMU/Plan/RoutePage";
import RoutePassedPage from "../SinglePages/ZMU/Acc/RoutePassedPage";
// import PassagePage_old from "../SinglePages/ZMU/Acc/PassagePage";
import PasAllPage from "../SinglePages/ZMU/Acc/PasAllPage";
import TablesPage from "../SinglePages/ZMU/Acc/TablesPage";
import AnimalPage from "../SinglePages/ZMU/Acc/AnimalPage";
import BirdPage from "../SinglePages/ZMU/Acc/BirdPage";
import AccGraphPage from "../Pages/Regbook/AccGraphPage";

export default {
    AddressPage,
    AdminPage,
    BankPropsPage,
    ContactPage,
    ContactRelPage,
    ContactsPage,
    DocumentPage,
    DocumentsPage,
    DtpPage,
    DtpsPage,
    Url,
    HuntDescPage,
    HuntCodesPage,
    EmailPage,
    FlashPanel,
    FooterPanel,
    Header,
    HuntEconPage,
    HuntEconsPage,
    HuntLandPage,
    HuntLandsPage,
    HuntUserPage,
    HuntUsersPage,
    Index,
    InlineMenu,
    LeftPanel,
    Login,
    Reset,
    MapPage,
    MappingPage,
    MappingsPage,
    DialogForm,
    MonitoringsPage,
    OfficePage,
    PermisionsPage,
    PhonePage,
    PickupPage,
    ProfilePage,
    ZmuProfilePage,
    ReestrsPage,
    ReportPage,
    ReportsPage,
    ResoursesPage,
    RightPanel,
    SpeciesPage,
    SpecPage,
    SpecViewPage,
    SpecRbPage,
    StaffPage,
    StocksPage,
    AccGraphPage,
    TestPage,
    TopPanel,
    UserPage,
    UserRolePage,
    UsersAdminPage,
    LogPage,
    NewPage,
    LogsPage,
    SessionsPage,
    NewsPage,
    ViolationPage,
    ViolationsPage,
    ZonePage,
    ZmuIndex,
    ZmuAccPage,
    EaPage,
    PasEaPage,
    RoutePage,
    // PassagePage: PassagePage_old,
    PasAllPage,
    TablesPage,
    BirdPage,
    AnimalPage,
    RoutePassedPage,
    SpecSynPage,
    SpecLitPage,
    SpecStatusPage,
    EventPage,
    ZmuGraphPage,
    PassCommentPage
};
