import React from "react";
import * as f from "../../common/Funcs";
import BaseGridPage from "../../Base/BaseGridPage";

const keyName = `AccGraphPage`;
const title = `График численности.`;
export default class AccGraphPage extends BaseGridPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName="AccGraphGrid";
        context.topMenuButtonNames = ['reload'];
        context.filters = [{name: 'year',pos:1, required: true, message: f.locale('needyear')},
            {name: 'accgraphspec',pos:2, required: true},
            {name: 'accgraph',pos:3, required: true},
            {name: 'accgraphfield',pos:4, required: false},
            {name:'region',pos:5,required: false},
            {name:'users',pos:6,required: false},
        ];
    }

}