import {Fill, Stroke, Style, Text} from "ol/style";
import * as g from "../../common/gisFuncs";

const keyName = 'oopt.js';

const getStyleDesc = (feature, resolution) => {
    const sa = feature.getProperties().name.toLowerCase();
    const styleDesc = {fill: {color: 'rgba(0,0,0,0)'}};
    // if (sa[0] != 'ф' ?? resolution > 1000) return styleDesc;
    let color = 'rgba(0,0,0,0)';
    let lineDash = null;
    switch (sa[0]) {
        case 'ф':
            color = 'rgba(255,0,0,1)';
            break;
        case 'р':
            color = 'rgba(255,100,100,1)';
            break;
        case 'м':
            color = 'rgba(255,100,0,1)';
            break;
    }
    if (sa.indexOf('охранная зона') != -1) {
        lineDash = [10, 5];
    }
    styleDesc.line = {color: color, width: 2, lineDash: lineDash};
    styleDesc.fill = {color: 'rgba(0,0,0,0)'};
    return styleDesc;
}

export default (feature, resolution) => {
    if (window.cssOn) console.debug(`${keyName} css`, feature);
    const styleDesc = getStyleDesc(feature, resolution);
    const w = new Style({
        stroke: new Stroke(styleDesc.line),
        fill: new Fill(styleDesc.fill),
        text: new Text({
            text: g.getText(feature, {resolution: resolution}), stroke: new Stroke({color: [255, 255, 255], width: 3}),
            fill: new Fill({color: 'black'}),
            overflow: (resolution < 50)
        })
    });
    return [w];
}