import React from 'react';
import {Cartesian, ExtGrid, LockedGrid, Panel,} from '@sencha/ext-react-modern';

import * as f from "../../common/Funcs";
import GridField from "../../collections/GridField";
import ErrorBoundary from "../../ErrorBoundary";
import AccGrid from "./AccGrid";
import BaseGrid from "../../Base/BaseGrid";
import BasePageGrid from "../../Base/BasePageGrid";

const keyName = `AccGraphGrid`;
const Ext = window['Ext'];
Ext.require('Ext.grid.plugin.Summary');

export default class AccGraphGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        context.rowNumberWidth = 40;
        context.columnsDefaults = [];
        const storeProps = {
            data: context.state.data
        };
        // storeProps.groupField = 'region_name';
        context.store = Ext.create('Ext.data.Store', storeProps);
    }

    // componentDidMount() {
    // }

    //загрузка данных
    getData(params) {
        const {context} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName(), params);
        const callback = (result) => {
            const store = [];
            const series = [{
                xField: 'acc_year', yField: [...new Set(result.data.map(r => r.field_id))],
                type: 'bar', title: [...new Set(result.data.map(r => r.field_label))],
                stacked: false,
                label: {
                    field: [...new Set(result.data.map(r => r.field_id))],
                    display: 'insideEnd',
                    fontSize: 10,
                    color: '#fff',
                    orientation: 'vertical',
                    // fontWeight: 'bold'
                },
                style: {
                    stroke: 'black',
                    lineWidth: 1
                },
            }
            ];
            result.data.forEach((row) => {
                let idx = store.findIndex(s => s.acc_year == row.acc_year);
                if (idx < 0) {
                    store.push({acc_year: row.acc_year});
                    idx = store.length - 1;
                }
                store[idx][row.field_id] = row.value;
            })
            context.store.clearData();
            context.setState({data: store, series: series});
        };
        context.setState({data: [], series: []}, () => {
            super.getData({context: context, callback: callback});
        });
    }

    afterEdit(context, sender, location, eOpts) {
        //признак измененной записи
        location.record.data.modified = true;
    }

    getHeight() {
        return f.getCentralHeight() - f.getInnerHeightMenu();
    }

    getGrid(context, columns) {
        const plugins = {
            gridfilters: true,
            gridexporter: true,
            gridsummaryrow: true
        };
        return <ErrorBoundary key={`${keyName}err`}><ExtGrid
            layout={'fit'}
            key={keyName + 'grid'}
            cls={'acc-grid'}
            name={keyName}
            height={context.getHeight()}
            maxHeight={context.getMaxHeight(context)}
            rowHeight={context.props.rowHeight}
            rowNumbers={{
                width: context.rowNumberWidth,
                docked: 'left'
            }}
            plugins={plugins}
            // groupField={context.groupField}
            groupHeader={{
                xtype: 'gridsummaryrow',
                floated: false
            }}
            platformConfig={{
                '!desktop': {
                    plugins: {
                        grideditable: true,
                        gridsummaryrow: true
                    }
                },
                desktop: {
                    plugins: {
                        // rowedit:true,
                        gridcellediting: true,
                        gridsummaryrow: true
                    }
                }
            }}
            itemConfig={context.getItemConfig(context)}
            store={context.store}
            ref={grid => context.grid = grid}
            variableHeights={false}
            listeners={{
                columnmenucreated(grid, column, menu, eOpts) {
                    menu.on('beforeshow', (sender) => {
                        sender.items.items.map(i => {
                            if (i.menu)
                                i.menu.getItems().items.map(c => {

                                    //!!добавить поля
                                    if (['acc_rights',
                                        'region_name',
                                        'huntuser_name',
                                        'acc_year',
                                        'acc_region',
                                        'acc_huntuser'
                                    ].indexOf(c.column._dataIndex) > -1) c.hide();
                                });
                            f.localeMenu(i);
                        });
                    });
                },
                edit: (sender, location, eOpts) => {
                    context.afterEdit(context, sender, location, eOpts)
                },
                beforeedit: (sender, location) => {
                    context.beforeEdit(context, sender, location)
                }
            }}
        >
            {columns}
        </ExtGrid></ErrorBoundary>
    }

    getColumn(context, c) {
        const hidden = ((context.hiddenColumns && context.hiddenColumns.indexOf(c.column_name) > -1) || c.hidden || c.column_name == 'order_value');
        const isNum = (c.data_type == 'integer' || c.data_type == 'numeric');
        const summary = ((isNum && !hidden) || (c.column_name == 'region_name')) ? 'max' : null;
        if (isNum) {
            c.width = 75;
            c.className = 'rotate-header'
        }
        c.minWidth = c.width;
        c.maxWidth = c.width;

        return <GridField
            key={c.column_name}
            column_name={c.column_name}
            column_label={c.column_label}
            // scrollDock={(c.docked)?'start':'end'}
            // docked={c.docked}
            // scrollable={(c.docked)}
            flex={c.flex}
            readonly={c.readonly}
            hidden={hidden}
            className={c.className}
            getColor={c.getColor}
            colored={c.colored}
            parent={context.props.parent}
            grid={context}
            relation={c.relation}
            title={c.title}
            align={c.align}
            ignoreExport={c.ignoreExport}
            editable={c.editable}
            data_type={c.data_type}
            width={c.width}
            minWidth={c.minWidth}
            maxWidth={c.maxWidth * ((context.state?.data?.length > 10) ? 0.95 : 1)}
            ignorePrint={c.ignorePrint}
            summary={summary}
            summaryRenderer={(x, scope, id, context, ...args) => {
                if (x) {
                    if (!context) {
                        context = {refreshContext: scope};
                        id = scope.dataIndex;
                    }
                    if (context.refreshContext.group) {
                        if (id == 'region_name') {
                            // if (window.IasConfig.devMode) console.debug(`${x} region sumrow`, arguments);
                            return x;
                        } else {
                            if (window.IasConfig.devMode) console.debug(`calc ${id} sumrow`, arguments);
                            return context.refreshContext.records.map(i => Number(i.getData()[id])).reduce((a, b) => a + b);
                            // const records=scope.group.data.items.map(t=>t.data);
                            const records = context.refreshContext.records;
                            return records.map(i => Number(i.getData()[id])).reduce((a, b) => a + b);
                        }
                    } else {
                        if (id == 'region_name') return 'Итого';
                        else {
                            // const records=scope.store.getData().items;
                            const records = context.refreshContext.store.getData().items;
                            return records.map(i => Number(i.getData()[id])).reduce((a, b) => a + b);
                        }
                    }
                } else return '';
            }
            }
            grouper={{
                groupFn: (...args) => {
                    debugger;
                }
            }}
        />
    }

    render() {
        const context = this;
        const theme = 'default';
        const legend = {
            type: 'sprite',
            position: 'bottom'
        };
        const content = [
            <Cartesian
                layout={'fit'}
                key={keyName + 'grid'}
                name={keyName}
                height={(context.props.height && context.props.height !== '100%') ? context.props.height : context.getHeight()}
                maxHeight={context.props.maxHeight || context.getMaxHeight(context)}
                maxWidth={context.props.maxWidth}
                width={context.props.width}
                minHeight={context.props.minHeight}
                minWidth={context.props.minWidth}
                insetPadding="20 10"
                store={context.state.data}
                scheme={theme}
                legend={legend}
                axes={[{
                    type: 'numeric',
                    position: 'left',
                    title: 'Численность, особей'
                }, {
                    type: 'category',
                    position: 'bottom',
                    title: 'Год'
                }]}
                series={context.state.series}
            />];
        return (
            <Panel key={`${keyName}gridpanel`} hidden={context.props.hidden}
                   ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
            >{content}</Panel>
        )
    }

}
