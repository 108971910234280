import ErrorBoundary from "../ErrorBoundary";
import React, {Component} from 'react';
import * as g from "../common/gisFuncs";
import * as f from "../common/Funcs";
import {Button, FileField, FormPanel, Panel} from "@sencha/ext-react-modern";
import {Draw, Modify, Snap} from 'ol/interaction';
import {AddButton, CancelButton, DownloadButton, DropButton, EditButton, UploadButton} from "../common/Buttons";
import SimpleMap from "./SimpleMap";
import {BaseTextButton} from "../Base/BaseButtons";
import ExtGrid from "@sencha/ext-react-modern/dist/ExtGrid";

let context = null;
const keyName = "SimpleImportMap";
export default class SimpleImportMap extends SimpleMap {

    static defaultProps = {
        mapButtonNames: ['upload', 'download', 'edit'],
        geomType: 'LineString'
    }

    constructor(props) {
        super(props);
        context = this;
        context.geomType = context.props.geomType || 'LineString';
        context.state.legendOn = false;
        context.state.coordinates = [];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        if (prevProps.rowId != context.props.rowId) {
            context.setState({buttons: context.addButtons()});
        }
        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    isEditRight(context) {
        const page = context.props.parent.props.parent;
        return page.isEditRight(context.props.rights);
    }

    render() {
        const context = this;
        const height = context.props.height;
        const width = context.props.width;
        const readonly = context.props.readonly;
debugger;
        let toolBox = '';
        if (!readonly) {
            const buttons = context.state.buttons;
            toolBox = <Panel layout={'vbox'} margin={3} key={`toolBoxPanel${keyName}`} name={`toolBoxPanel${keyName}`}>
                {buttons}
            </Panel>;
        }
        return <Panel
            layout={'hbox'} margin="3"
            key={`mapPanel${keyName}`}
            name={`mapPanel${keyName}`}
            className={'map-panel'}
            width={width}
            height={height}
            ref={p => context.cmp = p}
        >
            <div className={`map-${keyName}`} style={{height: height, width: width}}
                 ref={(m) => context.mapDiv = m}/>
            {toolBox}
        </Panel>;
    }

}
