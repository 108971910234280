import React from 'react';
import * as be from "../collections/baseElements";
import * as f from "../common/Funcs";
import {jsPDF} from "jspdf";

const Ext = window['Ext'];
let loclist={};
//основная структура интерфейса
export const panelsTemplate = {"TopPanel": [], "LeftPanel": [], "RightPanel": [], "FooterPanel": [], "InlineMenu": []};

export const getCurrentPage = () => window.location.search.match(/page=[\D\d]*/)[0].substr(5);

export const getTitle = (context, parentRow, relation) => {
    const grid = context.grid;
    let title = '';
    let currentRow = null;
    if (!grid) title = `нет заголовка, не найдена таблица ${context.grid._reactInternals.key}`;
    else {
        currentRow = (context.rowId) ? grid.getCurrentRow() : null;
        const cont = (relation) ? context.relation : context.editForm;
        title = (cont.titleFunc) ? cont.titleFunc(cont, currentRow, parentRow) : '';
        return context['dialog' + ((relation) ? '2' : '')].cmp.setTitle(title);
    }
}

//копия объекта
export const clone = (obj) => JSON.parse(JSON.stringify(obj));

//оболочка для сообщения - затравка для переделки
export const message_old = (params, callback) => {
    const msg =
        Ext.Msg.show({
            title: params.title, message: params.message, buttons: params.buttons, fn: (e, value) => {
                if (callback) callback((e == null) ? null : (e == 'yes' || e == 'ok'), value);
            }
        });
    if (!params.timeout) params.timeout = 5000;
    setTimeout(() => {
        try {
            msg.close();
            if (callback) callback();
        } catch (e) {
            if (callback) callback();
        } finally {
            window.unmask();
        }
    }, params.timeout);
}
export const message = (params, callback) => {
    let timeoutFunc = void (0);
    Object.keys(params.buttons).map(b => params.buttons[b].handler = (e) => {
        if (callback) {
            let result = ['yes', 'ok'].indexOf(b) > -1;
            if (!result && b == 'cancel') result = null;
            if (params.type == 'prompt') {
                let value = msg.innerElement.query('input');
                if (value.length == 0) result = false;
                value = value[0].value;
                if (!value) result = false;
                callback(result, value);
            } else {
                callback(result, b);
            }
        }
        timeoutFunc = () => {
        };
        msg.destroy();
    })
    const msg =
        Ext.create({
            xtype: 'dialog',
            title: params.title,
            html: params.message,
            buttons: params.buttons
        });
    msg.show();
    if (!params.timeout && params.type != 'prompt') params.timeout = 10000;
    if (params.timeout) {
        timeoutFunc = function () {
            if (callback) callback();
            msg.destroy();
            window.unmask();
        }
        setTimeout(() => {
            timeoutFunc();
        }, params.timeout);
    }
}

//вывести сообщения об ошибке на страницу
export const flashMessages = (res) => {
    if (Array.isArray(res)) res = {flash: res};
    if (!(res.flash && res.flash.length > 0)) return;
    //if (!panel) return;
    let message = '';
    res.flash.map(fl => {
        if (typeof (fl) == 'string') {
            message += fl;
        } else if (typeof (fl.message) == 'string') {
            message += fl.message + '\n';
            res.flash.map(fl => {
                if (fl.level == 'e') console.error(fl); else console.warn(fl);
            });
        }
    });
    /*    const panel = window.IasLoFlash;
        const messages=panel.state.messages||[];
        messages.push(message);
        panel.setState({messages});*/
    f.alert({title: `Сообщение сервера`, message: message});
};
export const setPageCount = (result) => {
    const PageFilter = window.IasLoApp?.filters?.PageFilter;
    if (result.pageCount && PageFilter && PageFilter.state?.pageCount != result.pageCount) PageFilter.setState({pageCount: result.pageCount});
}

//самозакрывающееся сообщение
export const toast = (params, callback) => {
    if (typeof params == 'string') params = {message: params};
    if (!params.timeout) params.timeout = 1500;
    params.title = params.title || 'Сообщение';
    params.buttons = {ok: {text: 'Ок'}};
    return message(params, callback);
}
//оболочка для сообщения - затравка для переделки
export const confirm = (params, callback) => {
    if (typeof (params) == 'string')
        params = {message: params};
    if (!params.buttons) params.buttons = {yes: {text: 'Да'}, no: {text: 'Нет'}};
    params.title = params.title || 'Сообщение';
    params.message = params.message || 'Подтвердите согласие.';
    params.type = 'confirm';

    return message(params, callback);
}

//оболочка для сообщения - затравка для переделки
export const prompt = (params, callback) => {
    if (typeof (params) == 'string') {
        params = {
            title: 'Сообщение',
            message: params
        };
    }
    return message({
        title: params.title || 'Сообщение',
        message: `${params.message || 'Параметр.'}<p/><div><input class='center-input' type="${params.datatype || 'text'}"/></div>`,
        level: params.level,
        type: 'prompt',
        buttons: {yes: {text: 'Ввод'}, no: {text: 'Отмена'}}
    }, callback);
};
//оболочка для сообщения - затравка для переделки
export const alert = (params, callback) => (typeof (params) == 'string') ?
    message({title: 'Сообщение', message: params, type: 'alert', buttons: {ok: {text: 'Ок'}}}, callback) :
    message({
        title: params.title || 'Сообщение',
        message: params.message || 'Внимание!',
        level: params.level,
        type: 'alert',
        buttons: {ok: {text: 'Ок'}}
    }, callback);

//управление подсказками
export const showTip = (context, txt, e) => {
    const ar = document.getElementsByClassName('qtip');
    let tip;
    if (ar.length > 0)
        tip = document.getElementsByClassName('qtip').getArray()[0];
    else {
        tip = document.createElement('div');
        tip.className = 'qtip';
        tip.innerHTML = txt;
        tip.style.top = (e.clientY + 5) + 'px';
        tip.style.left = (e.clientX + 5) + 'px';
        document.body.append(tip);
    }
}
export const hideTip = () => {
    const ar = document.getElementsByClassName('qtip');
    if (ar.length > 0) ar.getArray().map(i => i.remove());
}

//оболочка для сохранения canvas в pdf
export const toPdf = (params) => {
    const {image, pageSize = 'a4', pdfName = 'map'} = params;
    if (!image) return false;
    const dims = {
        a0: [1189, 841],
        a1: [841, 594],
        a2: [594, 420],
        a3: [420, 297],
        a4: [297, 210],
        a5: [210, 148],
    };
    const pdf = new jsPDF('landscape', undefined, pageSize);
    pdf.addImage(
        image.toDataURL('image/jpeg'),
        'JPEG',
        0,
        0,
        dims[pageSize][0],
        dims[pageSize][1]
    );
    pdf.save(pdfName + '.pdf');
}
window.toPdf = toPdf;

//оболочка для сохранения сгенерированного файла
export const saveFile = (params) => {
    let {blobFile, stringFile, fileName, fileType} = params;
    const buttonId = 'downloadbutton';
    const fordel = document.getElementById(buttonId);
    if (fordel) fordel.remove();
    const a = document.createElement('a');
    //  a.id = buttonId;
    if (blobFile) {
        a.href = blobFile;
    }
    if (stringFile) {
        a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(stringFile);
    }
    a.download = `${fileName}.${fileType || 'und'}`;
    a.click();
}

//сохранение файла
export const downloadFile = (path) => {
    const link = document.createElement('a');
    link.href = `${window.IasConfig.homePath}files${path}`;
    link.download = `${path.split('\\').splice(-1)[0]}`;
    link.target = "_blank";
    link.download = 'download';
    document.body.appendChild(link);
    link.click();
    link.remove();
}

window.toArray = (item) => Array.prototype.slice.apply(item);

window.mask = (params = {}) => {
    if (window.IasConfig.devMode) console.debug('mask');
    let {object = window.IasLoApp?.centerPanel, message = 'загрузка данных...'} = params;
    if (object?.cmp) object.cmp.mask({xtype: 'loadmask', indicator: true, cls: 'load-mask', message: message})
}

window.unmask = (params = {}) => {
    if (window.IasConfig.devMode) console.debug('unmask');
    let {object = window.IasLoApp?.centerPanel} = params;
    if (object?.cmp) object.cmp.unmask();
    window.toArray(document.getElementsByClassName('x-mask')).map(e => e.remove());
}
export const getBool = (obj) => {
    if (typeof (obj) == 'function') return obj();
    else return Boolean(obj);
}

/*возвращает имя вызвавшей функции*/
window.funcName = () => {
    const s = (new Error()).stack.split('\n')[2];     //строка из сообщения об ошибке
    return s.substring(s.indexOf('.') + 1, s.indexOf(' ', s.indexOf('.')));     //выбираем от первой точки до следующего пробела
}

//задать куку
document.setCookie = (cookieName, value) => {
    if (!value && value !== 0 && value != false) document.dropCookie(cookieName);
    else document.cookie = `${cookieName}=${escape(value)};max-age=${60 * 60 * 24 * 30 * 1000}`
};
//удалить куку
document.dropCookie = (cookieName) => {
    document.cookie = `${cookieName}=;max-age=${-1}`
};

document.getCookies = () => document.cookie.split(';').map(c => c.split('=')[0]).map(c => c.trim());

//очистить не заданные куки
document.clearOther = (sett) => {
    document.getCookies().filter(n => n.substr(-6) == 'Filter').filter(f => sett.map(d => `${(d.name) ? d.name : d}Filter`).indexOf(f) == -1).map(c => document.dropCookie(c));
};

//костыль - поправить куки, если в разных окнах они разные
document.setCookies = () => {
    const filters = window.IasLoApp?.filters;
    if (!filters) return;
    Object.keys(filters).map(k => {
        if (k != 'AcFilter')
            if (filters[k] && filters[k].getValue() && !filters[k].props.hidden)
                (document.setCookie(k.charAt(0).toLocaleLowerCase() + k.substring(1), filters[k].getValue()))
            else (document.dropCookie(k.charAt(0).toLocaleLowerCase() + k.substring(1)))
    });
};

//получить куку
document.getCookie = (cookieName) => {
    try {
        let cook = document.cookie.replace(/ /g, "").split(";").filter(s =>
            s.split("=")[0] == cookieName);
        if (!cook || cook.length == 0) return null;
        cook = unescape(cook[0].split("=")[1]);
        switch (cook) {
            case "false":
                return false;
            case "true":
                return true;
            case "null":
            case "undefined":
                return null;
            default:
                return cook;
        }
    } catch (error) {
        if (cookieName == 'yearFilter')
            return (new Date()).getFullYear();
        else
            return null;
    }
};

//удалить все куки
document.clearCookies = () => {
    const cookies = document.cookie.split(";");
    cookies.map((cookie) => {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    })
};

document.getRGB = (e) => {
    let keys = [e % 2, e % 5, e % 11];
    let max = keys.reduce((i, r) => (i > r) ? i : r, 0);
    if (max == 0) return [150, 150, 150, 0.1]; else max = Math.floor(255 / max);
    let min = keys.reduce((i, r) => (i < r) ? i : r, 10000);
    keys[e % 3] = max;
    keys[(e + 1) % 3] = min;
    keys = keys.map(k => k * max);
    return [keys[0], keys[1], keys[2], 0.2];
}

//взять свойство по имени класса
document.getCssProp = (className, propName) => {
    const el = document.getElementsByClassName(className);
    if (el.length == 0) {
        return Array.prototype.slice.apply(document.styleSheets).reduce(
            (a, b) => [...a, ...b.rules], []).filter(
            (r) => r.selectorText && r.selectorText.indexOf(className) > -1 && r.style[propName] != '').sort(
            (a, b) => b.selectorText.length - a.selectorText.length)[0].style[propName];
    }
    return getComputedStyle(el[0])[propName];
};
//высота внутреннего меню
export const getInnerHeightMenu = () => {
    let result = document.getCssProp('x-inner-el', 'height');
    if (isNaN(result)) result = 30;
    return result;
};
//получить имя страницы из адресной строки
export const getPageName = () => {
    try {
        return document.location.search.substr(1).split("&").filter(s => s.split("=")[0] == "page")[0].split("=")[1];
    } catch (error) {
        window.history.pushState('', '', `${document.location.origin}?page=Index`);
        return "Index";
    }
};

export const getRoles = () => {
    let isauth = document.getCookie('isAuth');
    if (isauth) isauth = JSON.parse(isauth.substring(2));
    return isauth;
}
//получить панели для страницы
export const getPanels = async (params) => {
    let {context, keyName, callback, pagecallback} = params;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}api/page/${keyName}/panels`, {
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                /*распределение по панелям*/
                if (window.IasConfig.devMode) console.debug(`загрузка getPanels, result: ${result}`);
                flashMessages(result);
                const panels = clone(panelsTemplate);
                if (!result) {
                    if (callback) {
                        return callback(panels);
                    }
                }
//возвращаем панели
                if (result.panels && callback) {
                    const r = result.panels.find(p => p.element_url == window.location.search.match(/page=[\D\d]*/)[0].substr(5));
                    if (r) document.setCookie('rights', r.role_right);
                    result.panels.map((p) => {
                        const isCurrent = f.getCurrentPage() == p.element_url;
                        if (isCurrent) {
                            const pn = result.panels.find(pp => pp.element_url == p.page);
                            if (pn) pn.isSelected = true;
                        }
                        panels[p.block_name].push(React.createElement(be[p.element_type], {
                            element_func: p.element_func,
                            element_url: p.element_url,
                            role_rights: p.role_right,
                            name: p.page_element,
                            key: p.page_element,
                            text: p.text,
                            parent: context,
                            page: "ZmuPlanPage",
                            pressed: isCurrent || p.isSelected,
                            appViewPort: context.props.appViewPort || context,
                            style: {}
                        }));
                    });
                    panels.flash = result.flash || [];
                    callback(panels);
                } else if (callback) callback([]);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    window.IasLoApp.rePage(result.page);
                }
            },
            (error) => {
                if (callback) flashMessages([{level: 'e', message: JSON.stringify(error.message)}]);
            }
        )
};

export const getFilter = (filterName) => {
    const filter = window.IasLoApp?.filters[`${filterName}Filter`];
    if (filter?.props && !filter.props.hidden && filter.getValue())
        return filter.getValue(); else return null;
}

export const getFilters = (format = 'string') => {
    let result = ('string') ? '' : ('array') ? [] : {};
    const filters = window.IasLoApp?.filters;
    if (filters) {
        Object.keys(filters).map(f => {
                const filter = filters[f];
                if (filter&&!filter.props.hidden && filter.getValue()) {
                    const name = f.replace('Filter', '').toLowerCase();
                    switch (format) {
                        case "string":
                            result = result + `&${name}=${filter.getValue()}`;
                            break;
                        case "array":
                            result.push({[name]: filter.getValue()});
                            break;
                        case "object":
                            result[name] = filter.getValue();
                            break;
                    }
                }
            }
        )
        if (format == 'string') {
            result = result.substring(1);
        }
    } else {
        document.getCookies().filter(f => f.indexOf('Filter') > -1).map(f => {
            const filter = document.getCookie(f);
            if (exist(filter)) {
                const name = f.replace('Filter', '').toLowerCase();
                switch (format) {
                    case "string":
                        result = result + `&${name}=${filter}`;
                        break;
                    case "array":
                        result.push({[name]: filter});
                        break;
                    case "object":
                        result[name] = filter;
                        break;
                }
            }
        })
    }
    return result;
};

export const callRemoveBlanks = (filterName,filterValue,groupName) =>{
    let filters = f.getFilters('string');
    if (filters.indexOf(`${filterName}=`) == -1) filters += `&${filterName}=${filterValue}`;
    f.callPath({method: 'GET',path: `forms/recreate/${groupName}?${filters}`});
};


//заполнить пустые ячейки ''
export const fixNullCells = (data, columns) => {
    const result = [];
    if (!(data && data.map)) return data;
    data.map(r => {
        const row = {};
        Object.keys(r).map(k => {
            row[k] = fixNull(r[k]);
        })
        result.push(row);
    })
    return result;
}

export const fixNull = (val) => ([undefined, null].indexOf(val) > -1) ? '' : val;

export const pageAbort = () => {
    if (window.IasConfig.pageAbort) window.IasConfig.pageAbort.abort();
    window.unmask();
    window.IasConfig.pageAbort = new AbortController();
    return window.IasConfig.pageAbort;
};

//данные для страницы-грида
export const getPageData = async (params) => {
    let {keyName, callback, filtersOn, pageSize, np} = params;
    const filters = getFilters('string');
    if ((np) || (window.IasLoApp?.filters?.PageFilter?.props?.hidden)) np = '?np=true';
    else if (pageSize) np = `?p=${pageSize}`;
    else np = '?1=1';
    if (filters.length > 1) np += `&${filters}`;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}api/page/${keyName + '/'}get/${(filtersOn) ? 'filter' : 'all'}${np}`, {
        signal: pageAbort().signal,
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`загрузка getPageData, result: ${result}`);
                if (!result)
                    if (callback) return callback({data: [], columns: [], flash: []});
                const PageFilter = window.IasLoApp?.filters?.PageFilter;
                if (result.pageCount && PageFilter && PageFilter.state?.pageCount != result.pageCount) PageFilter.setState({pageCount: result.pageCount});
                if (result.columns) {
                    result.columns.map(c => {
                        if (c.column_name.indexOf('path') > -1) c.data_type = 'path';
                        if (c.column_name.indexOf('rights') > -1) {
                            c.maxWidth = 100;
                            c.pos = 100;
                        }
                    });
                    const jsString = JSON.stringify(result.columns.map(c => {
                        return {column_name: c.column_name, pos: 0}
                    }));
                }
                flashMessages(result);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                if (result.data) result.data = fixNullCells(result.data, result.columns);
                if (callback) return callback(result);
            },
            (error) => {
                if (error.name == 'AbortError') return;
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages(
                    [{level: 'e', message: JSON.stringify(error.message)}]
                );
            }
        )
};

//данные для страницы-учета
export const getAccPageData = async (params) => {
    let {context, accId, callback, filters,graph=false} = params;
    document.setCookies();
    filters = getFilters('string');
    if (graph) filters += '&graph=true';
    fetch(`${window.IasConfig.homePath}api/acc/${accId}?${filters}`, {
        signal: pageAbort().signal,
        credentials: 'include'
    })
        // fetch(`${window.IasConfig.homePath}api/acc/${accId}/${filters.map(f => f.name).toString().replaceAll(',', '_')}`, {
        //     credentials: 'include'
        // })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`загрузка getAccPageData, result: ${result}`);
                if (!result)
                    if (callback) return callback({data: [], columns: [], flash: []});
                if (result.columns) {
                    result.columns.map(c => (c.column_name.indexOf('path') > -1) ? c.data_type = 'path' : '');
                    const jsString = JSON.stringify(result.columns.map(c => {
                        return {column_name: c.column_name, pos: 0}
                    }));
                }
                flashMessages(result);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                if (result.data) result.data = fixNullCells(result.data);
                if (callback) return callback(result);
            },
            (error) => {
                if (error.name == 'AbortError') return;
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages([{level: 'e', message: JSON.stringify(error.message)}]);
            }
        )
};

//данные для грида, без указания прав
export const getViewData = async (params) => {
    let {context, viewName, filterString, fieldNames, callback} = params;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}api/data/${viewName + '/'}view${(filterString) ? ('?' + filterString) : ''}${(fieldNames) ? `fields=${fieldNames}` : ''}`, {
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (!result)
                    if (callback) return callback({data: [], columns: [], flash: []});
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                if (result.columns) {
                    result.columns = result.columns.map(c => {
                        if (context.columnsDefaults) {
                            const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                            c = {...c, ...def};
                        }
                        return c;
                    }).sort((a, b) => {
                        return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                    });
                    result.columns.map(c => {
                        if (c.column_name.indexOf('path') > -1) c.data_type = 'path';
                        if (c.column_name.indexOf('rights') > -1) {
                            c.maxWidth = 100;
                            c.pos = 100;
                        }
                    });
                    const jsString = JSON.stringify(result.columns.map(c => {
                        return {column_name: c.column_name, pos: 0}
                    }));
                }
                flashMessages(result);
                if (result.data) result.data = fixNullCells(result.data);
                if (callback) return callback(result);
            },
            (error) => {
                if (error.name == 'AbortError') return;
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};

export const maxZIndex_old = () => {
    const dd = window.toArray(document.getElementsByTagName('div'));
    let zI = 0;
    const mx = dd.map((r) => {
        const v = Number.parseInt(document.defaultView.getComputedStyle(r, null).getPropertyValue("z-index"), 10) || 1;
        if (v < 1000 && v > zI) zI = v;
    });
    if (zI < 100) return 100;
    else
        return zI;
}

//данные для грида, без указания прав
export const getRelData = async (params) => {
    let {context, viewName, values, callback, id} = params;
    const data = new FormData();
    Object.keys(values).map(k => data.append(k, values[k]));
    const requestOptions = {
        method: 'POST',
        //     mode:'no-cors',
        // headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: data
    };
    document.setCookies();
    fetch(`${window.IasConfig.homePath}api/data/${viewName + '/'}rel/${id}`, requestOptions)
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (!result)
                    if (callback) return callback({data: [], columns: [], flash: []});
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                if (result.columns) {
                    result.columns = result.columns.map(c => {
                        if (context.columnsDefaults) {
                            const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                            c = {...c, ...def};
                        }
                        return c;
                    }).sort((a, b) => {
                        return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                    });
                    result.columns.map(c => {
                        if (c.column_name.indexOf('path') > -1) c.data_type = 'path';
                        if (c.column_name.indexOf('rights') > -1) {
                            c.maxWidth = 100;
                            c.pos = 100;
                        }
                    });
                    const jsString = JSON.stringify(result.columns.map(c => {
                        return {column_name: c.column_name, pos: 0}
                    }));
                }
                flashMessages(result);
                if (result.data) result.data = fixNullCells(result.data);
                if (callback) return callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};

//строка из таблицы
export const getObjectData = async (params, url = 'row') => {
    let {context, tableName, id, callback} = params;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}api/data/${tableName + '/'}${url}/${id}`, {
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`загрузка getObjectData, result: ${result}`);
                if (!result)
                    if (callback) return callback({data: [], columns: [], flash: []});
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                if (result.columns) {
                    const path_columns = result.columns.filter(c => (c.column_name.indexOf('path') > -1));
                    path_columns.map(c => c.data_type = 'path');
                    if (path_columns.length > 0 && (f.isEditRight(result.rights))) {
                        path_columns.map(c => {
                            result.columns.push({
                                column_name: `${c.column_name}_upload`,
                                data_type: "upload",
                                pos: c.pos,
                            })
                        })
                    }
                }
                flashMessages(result);
                if (callback) return callback(result);
            },
            (error) => {
                if (callback) callback({data: [], columns: []});
                flashMessages({flash: [{level: 'e', message: JSON.stringify(error.message)}]});
            }
        )
};

//строка из таблицы в формате "единственная запись"
export const getSingleData = (params) => getObjectData(params, 'single');

//запись данных формы в базу
export const setObjectData = async (params) => {
    let {context, tableName, id, relation, data, rowId, form, values, path, callback} = params;
    if (window.IasConfig.devMode) console.debug(`отправка setObjectData, params: ${params}`);
    if (!path) path = `api/data/${tableName}/row/`;
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
    };
    if (!data) {
        data = (values) ? values : form.cmp.getValues();
        // requestOptions.headers= {'Content-Type':  'application/x-www-form-urlencoded'};
        requestOptions.body = data;
    } else {
        requestOptions.headers = {'Content-Type': 'application/json'};
        if (relation) {
            data.relation = relation;
            if (!data.relation.rowId && rowId) data.relation.rowId = rowId;
        }
        requestOptions.body = JSON.stringify(data);
    }

    document.setCookies();
    fetch(`${window.IasConfig.homePath}${path}${id}`,
        requestOptions
    ).then(res => {
            return res.json();
        }
    )
        .then(
            (result) => {
                flashMessages(result);
                if (callback) callback(result);
            },
            (error) => {
                if (callback) callback({data: [], columns: []});
                flashMessages({flash: [{level: 'e', message: JSON.stringify(error.message)}]});
            })
};

//запись данных формы в базу
export const callPath = async (params) => {
    let {id, method = 'GET', values, path, callback} = params;
    if (window.IasConfig.devMode) console.debug(`отправка callPath, params: ${params}`);
    if (!path) return;
    const requestOptions = {
        method: method,
        credentials: 'include',
    };
    if (method == 'POST') requestOptions.body = values;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}${path}${(id) ? id : ''}`,
        requestOptions
    ).then(res => {
            return res.json();
        }
    )
        .then(
            (result) => {
                flashMessages(result);
                setPageCount(result);
                if (callback) callback(result);
            },
            (error) => {
                if (callback) callback({data: [], columns: []});
                flashMessages({flash: [{level: 'e', message: JSON.stringify(error.message)}]});
            })
};

//запись данных формы в базу
export const callFile = async (params) => {
    let {id, method = 'GET', values, path, callback} = params;
    if (window.IasConfig.devMode) console.debug(`отправка callPath, params: ${params}`);
    if (!path) return;
    const requestOptions = {
        method: method,
        credentials: 'include',

    };
    if (method == 'POST') requestOptions.body = values;
    let filename = '';
    document.setCookies();
    fetch(`${window.IasConfig.homePath}${path}${id}`,
        requestOptions
    ).then(res => {
            const header = res.headers.get('Content-Disposition');
            const parts = header.split(';');
            filename = parts[1].split('=')[1];
            filename = filename.replaceAll('"', '').replaceAll(' ', '');
            return res.blob();
        }
    )
        .then(
            (blob) => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                // if (callback) callback(result);
            },
            (error) => {
                if (callback) callback({data: [], columns: []});
                flashMessages({flash: [{level: 'e', message: JSON.stringify(error.message)}]});
            })
};

//получить geo данные
export const getGeoData = async (params) => {
    let {tableName, id, idName, type, callback} = params;
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    };
    let url;
    switch (type) {
        case 'shp':
            url = `${window.IasConfig.homePath}geo/shp/${tableName}/${idName}/${id}`;
            break;
        default:
            url = `${window.IasConfig.homePath}gis/${tableName}/gpx/${idName}/${id}`;
    }
    // fetch(`${window.IasConfig.homePath}gis/${tableName}/gpx/${idName}/${id}`,
    document.setCookies();
    fetch(url,

        //   fetch(`${window.IasConfig.homePath}gis/${tableName}/gpx/${idName}/${id}`,
        requestOptions
    ).then(res => {
            switch (type) {
                case 'shp':
                    return res.blob();
                default:
                    return res.json();
            }
        }
    ).then(
        (result) => {
            switch (type) {
                case 'shp':
                    var url = window.URL.createObjectURL(result);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = `${tableName}_${id}.zip`;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();
                    break;
                default:
                    if (window.IasConfig.devMode) console.debug(`отправка setObjectData, result: ${result}`);
                    flashMessages(result);
                    if (callback) callback(result);
            }

        },
        (error) => {
            if (callback) callback({
                data: [],
                columns: []
            });
            flashMessages({
                flash: [{level: 'e', message: JSON.stringify(error.message)}]
            });
        })
};

//запись данных формы в базу
export const setGeoData = async (params) => {
    let {tableName, id, type, idName, form, values, callback} = params;
    const data = (values) ? values : form.cmp.getValues();
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        body: data
    };
    let url;
    switch (type) {
        case 'zip':
            url = `${window.IasConfig.homePath}geo/shp/${tableName}/${idName}/${id}`;
            break;
        default:
            url = `${window.IasConfig.homePath}gis/${tableName}/gpx/${idName}/${id}`;
    }
    // fetch(`${window.IasConfig.homePath}gis/${tableName}/gpx/${idName}/${id}`,
    document.setCookies();
    fetch(url,
        requestOptions
    ).then(res => {
            return res.json();
        }
    ).then(
        (result) => {
            if (window.IasConfig.devMode) console.debug(`отправка setObjectData, result: ${result}`);
            flashMessages(result);
            if (callback) callback(result);
        },
        (error) => {
            if (callback) callback({
                data: [],
                columns: []
            });
            flashMessages({
                flash: [{level: 'e', message: JSON.stringify(error.message)}]
            });
        })
};

//запись данных формы в базу
export const setGeoJsonData = async (params) => {
    let {tableName, id, idName, geoJson, callback} = params;
    const data = {geoJson: geoJson};
    /*А это работает?*/
    const requestOptions = {
        method: 'POST',
        //     mode:'no-cors',
        credentials: 'include',
        body: data
    };
    document.setCookies();
    fetch(`${window.IasConfig.homePath}gis/${tableName}/geojson/${idName}/${id}`,
        requestOptions
    ).then(res => {
            return res.json();
        }
    ).then(
        (result) => {
            if (window.IasConfig.devMode) console.debug(`отправка setObjectData, result: ${result}`);
            flashMessages(result);
            if (callback) callback(result);
        },
        (error) => {
            if (callback) callback({
                data: [],
                columns: []
            });
            flashMessages({
                flash: [{level: 'e', message: JSON.stringify(error.message)}]
            });
        })
};

//запись данных формы в базу
export const setAccsData = async (params) => {
    let {context, accName, values, callback} = params;
    const data = new FormData();
    Object.keys(values).map(k => {
        data.append(k, JSON.stringify(values[k]));
    })
    if (!data) return false;
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        body: data
    };
    document.setCookies();
    fetch(`${window.IasConfig.homePath}api/acc/`,
        requestOptions
    ).then(res => {
            return res.json();
        }
    )
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`отправка setAccsData, result: ${result}`);
                flashMessages(result);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                if (callback) callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            })
};

//строка пользователи
export const getUserData = async (params) => {
    let {context, tableName, id, callback} = params;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}users/get/${id}`, {
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`загрузка getUserData, result: ${result}`);
                if (!result)
                    if (callback) return callback({data: [], columns: [], flash: []});
                flashMessages(result);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    window.IasLoApp.rePage(result.page);
                }
                if (callback) return callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};

//запись пользователи
export const setUserData = async (params) => {
    let {context, tableName, id, relation, rowId, form, callback} = params;
    // let {context, tableName, id, relation, rowId, form, callback} = params;
    const values = form.cmp.getValues();
    const data = new FormData();
    Object.keys(values).map(k => {
        data.append(k, values[k]);
    })
    if (relation) {
        data.relation = relation;
        data.relation.rowId = rowId;
        // data.relation.rowId = rowId;
    }
    const requestOptions = {
        method: 'POST',
        //     mode:'no-cors',
        // headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: data
    };
    document.setCookies();
    fetch(`${window.IasConfig.homePath}users/set/${id}`,
        requestOptions
    ).then(res => {
            return res.json();
        }
    )
        .then(
            (result) => {
                // if (result.flash) {
                //     result.flash.map(fl => message(`Сообщение сервера: ${fl.message}`))
                // }
                if (window.IasConfig.devMode) console.debug(`отправка setUserData, result: ${result}`);
                flashMessages(result);
                if (result.data && result.data.fio && result.isProfile) document.setCookie('userfio', result.data.fio);
                // if (result.data) result.data = fixNullCells(data);
                if (callback) callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            })
};

//дерево боивидов
export const getSpecData = async (params) => {
    let {context, parentId, callback, filterString = ''} = params;
    if (!window.IasLoApp || ['login', 'reset', 'url'].indexOf(window.IasLoApp.state.pageName.toLowerCase()) > -1) return;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}species/${parentId}/rus/${(!filterString) ? '' : filterString}`, {
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`загрузка getSpecData, result: ${result}`);
                if (!result)
                    if (callback) return callback({data: [], flash: []});
                flashMessages(result);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                if (callback) return callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};

export const callRelationSend = async (params) => {
    let {props, items, callback, replace} = params;
    const values = Object.assign(props.relation, {
        relValue: items.map(item => item.data[props.relation.idName] || item.data[props.idName]),
        rowId: props.rowId,
        // rowId: props.rowId,
        replace: replace
    });
    setRelationData({values, callback: callback});
};
//запись данных формы в базу
export const setRelationData = async (params) => {
    let {values, callback} = params;
    // const data = new FormData();
    // Object.keys(values).map(k => data.append(k, values[k]));
    const requestOptions = {
        method: 'POST',
        //     mode:'no-cors',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        // body: data
        body: JSON.stringify(values)
    };
    document.setCookies();
    fetch(`${window.IasConfig.homePath}api/data/${values.tableName}/relation`,
        requestOptions
    ).then(res => {
            return res.json();
        }
    )
        .then(
            (result) => {
                // if (result.flash) {
                //     result.flash.map(fl => message(`Сообщение сервера: ${fl.message}`))
                // }
                if (window.IasConfig.devMode) console.debug(`отправка setRelationData, result: ${result}`);
                flashMessages(result);
                if (result.data) result.data = fixNullCells(result.data);
                if (callback) callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            })
};

//справочники к таблице
export const getRefData = (params) => {
    let {context, tableName, idField, titleField, filterString, callback,abortController} = params;
    filterString = filterString.replaceAll(/(\d\d)\.(\d\d)\.(\d\d\d\d)/g, '$3-$2-$1');
    if (!window.IasLoApp || ['login', 'reset', 'url'].indexOf(window.IasLoApp.state.pageName.toLowerCase()) > -1) return;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}api/data/${tableName}/ref/${idField}/${titleField}${(filterString) ? ('?filter=' + filterString) : ''}`, {
        signal:abortController?.signal,
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`загрузка getRefData, result: ${result}`);
                flashMessages(result);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                //   if (result.data) result.data = fixNullCells(result.data);
                if (callback) callback(result);
            },
            (error) => {
                if (error.name == 'AbortError') return;
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};

//удалить запись
export const dropObject = async (params) => {
    let {context, path, tableName, id, callback} = params;
    if (!path) path = `api/data/${tableName}/row/`;
    const requestOptions = {
        method: 'DELETE',
        credentials: 'include',
    };
    document.setCookies();
    fetch(`${window.IasConfig.homePath}${path}${id}`,
        requestOptions
    ).then(res => {
            return res.json();
        }
    )
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`удаление dropData, result: ${result}`);
                flashMessages(result);
                if (callback) callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            })
};

//печать через формирование json
export const getOutputFile = async (params) => {
    let {jsonString, typeoffile, file, filename, callback} = params;
    const data = new FormData();
    data.append('json', JSON.stringify(jsonString));
    data.append('typeofFile', typeoffile);
    if (file) data.append('image', file);
    const requestOptions = {
        method: 'POST',
        body: data,
        credentials: 'include',
        //  headers: {'Content-Type': 'multipart/form-data'},
    };
    document.setCookies();
    fetch(`${window.IasConfig.homePath}output/file`,
        requestOptions
    ).then(res => {
            if (res.ok == true) {
                return res.blob();
            } else return false;
        }
    )
        .then(
            (blob) => {
                if (!blob)
                   return flashMessages({
                        flash: [{level: 'e', message: 'Ошибка печати'}]
                    });
                const downloadUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = `${filename || ''}.${typeoffile}`;

                document.body.appendChild(link);

                link.click();
                link.remove();

                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL.
                    // https://bugzilla.mozilla.org/show_bug.cgi?id=1282407
                    window.URL.revokeObjectURL(downloadUrl);
                }, 100);

                return true;

                // if (result.flash) {
                //     result.flash.map(fl => message(`Сообщение сервера: ${fl.message}`))
                // }
                // if (window.IasConfig.devMode) console.debug(`получение файла экспорта`);
                // flashMessages(result);
                // if (result.data && result.data.fio && result.isProfile) document.setCookie('userfio', result.data.fio);
                // // if (result.data) result.data = fixNullCells(data);
                // if (callback) callback(result);
            },
            (error) => {
                debugger;
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            })

}

//проверка на существование переменной
export const exist = (value = null) => {
    if ([0, "0", Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY].indexOf(value) > -1) return true;
    if (typeof (value) == "object" && value != null && (Object.keys(value).length + Object.keys(value.__proto__).length > 0)) return true;
    try {
        if (["null", "[]", '""'].indexOf(JSON.stringify(value)) > -1 ||
            typeof (value) == 'undefined') return false;
    } catch (e) {
        debugger;
    }
    return true;
};

//проверка на наличие всех уровней объекта
export const validObject = (obj, levels) => {
    if (typeof (levels) == 'string') levels = [levels];
    if (!Array.isArray(levels)) return obj;
    const result =
        levels.reduce((a, l, i) =>
                (a && Object.keys(a).indexOf(l) > -1) ? a[l] : null
            , obj);
    return result;
}


/*дизайн, перевод*/

//перевод подписи
export const locale = (str) => {
    if (Object.keys(loclist)<1) {
        if (!window.IasLoApp?.state?.globalContext) return '';
        loclist = window.IasLoApp.state.globalContext.locales;
    }
    let result = str;
    if (!(str) || isNaN(str) == false) return '';
    if (loclist[str]) {
        return loclist[str];
    } else if (isNaN(Number(str.slice(-1))) == false) {
        return locale(str.slice(0, -1))
    } else if (str.indexOf('.') > -1) {
        result = loclist[str.substr(str.indexOf('.') + 1)];
    }
    return result;
}

//транслитерация названий
export const translit = (str) => {
    const transTable = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'e', 'ж': 'zh',
        'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
        'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h',
        'ц': 'c', 'ч': 'ch', 'ш': 'sh', 'щ': 'sh', 'ъ': '',
        'ы': 'i', 'ь': '', 'э': 'e', 'ю': 'yu', 'я': 'ya', ',': '', ';': '_', ' ': '_'
    }
    if (str === '') return '';
    str = str.toLowerCase();
    return str.split('').map(i =>
        //если число или англ. буква
        (/[a-z0-9\(\)]/.test(i) ? i :
            //если русская буква или пробел
            (/[а-яё]/.test(i)) ? transTable[i] : "_")).toString().replaceAll(',', '');

}
//перевод меню
export const localeMenu = (i) => {
    if (i.getText)
        i.setText(f.locale(i.getText()));
    if (i.getPlaceHolder)
        i.setPlaceHolder(f.locale(i.getPlaceHolder()));
    if (i.getLabel)
        i.setLabel(f.locale(i.getLabel()));
    if (i.menu) i.menu.getItems().items.map(ii => localeMenu(ii));
}

//высота центрального контейнера
export const getCentralHeight = () => {
    const c = window.IasConfig;
    // const result = window.innerHeight - c.headerHeight - c.topHeight - c.footerHeight - c.margin * 8 - 20;
    const result = window.innerHeight - c.headerHeight - c.topHeight - c.margin * 8 - 20;
    // const result = window.innerHeight - c.margin * 4;
    return result;
}

//ширина центрального контейнера
export const getCentralWidth = () => {
    const width = (document.querySelector('#innerPage')) ?
        document.querySelector('#innerPage').cmp.innerElement.getWidth() :
        window.innerWidth - 500;
    return width;

}

//права сотрудника комитета
export const isCommittee = () => {
    try {
       return Boolean(document.getCookie('isAuth').slice(3,-1).split(',').find(r=>r==10||r==11))
    } catch {
        return false;
    }
}

//админ
export const isAdmin = () => {
    try {
        return Boolean(document.getCookie('isAuth').slice(3,-1).split(',').find(r=>r==1))
    } catch {
        return false;
    }
}

//админ ЗМУ
export const isZmuAdmin = () => {
    try {
        return Boolean(document.getCookie('isAuth').slice(3,-1).split(',').find(r=>r==10))
    } catch {
        return false;
    }
}

//разрешено ли добавление
export const isAddRight = (letter) => {
    try {
        return ['a', 'd', 'u'].indexOf(letter) > -1;
    } catch {
        return false;
    }
}

//разрешено ли редактирование
export const isEditRight = (letter) => {
    try {
        return ['u', 'd'].indexOf(letter) > -1;
    } catch {
        return false;
    }
}

//разрешено ли удаление
export const isDropRight = (letter) => {
    return ['d'].indexOf(letter) > -1;
}

export const getGridFilter = (_type) => {
    switch (_type) {
        case "text":
        case "character varying":
            return {
                type: 'string',
                /*         menu: {
                             items: {
                                 like: {
                                     placeholder: 'Контекстный поиск...'
                                 }
                             }
                         }
                         */
            };
        case "integer":
        case "number":
            return {
                type: 'number',
                /*               menu: {
                                   items: {
                                       lt: {
                                           label: 'Меньше',
                                           placeholder: 'Меньше чем...',
                                       },
                                       gt: {
                                           label: 'Больше',
                                           placeholder: 'Больше чем...',
                                       },
                                       eq: {
                                           label: 'Точная дата',
                                           placeholder: 'Введите дату...',
                                       }
                                   }
                               }*/
            }
        case "date":
            return {
                type: 'date',
                /*          menu: {
                              items: {
                                  lt: {
                                      label: 'Меньше',
                                      placeholder: 'Меньше чем...',
                                      dateFormat: 'd-m-y'
                                  },
                                  gt: {
                                      label: 'Больше',
                                      placeholder: 'Больше чем...',
                                      dateFormat: 'd-m-y'
                                  },
                                  eq: {
                                      label: 'Точная дата',
                                      placeholder: 'Введите дату...',
                                      dateFormat: 'd-m-y'
                                  }
                              }
                          }*/
            }
        case "boolean":
            return {
                type: 'boolean',
                /* menu: {
                     items: {yes: {text: 'Да'}, no: {text: 'Нет'}}
                 }*/
            }
    }
}

//запрос пересчета (для ЗМУ)
export const callCalcs = async (params) => {
    let {context, zmuTableId, tableId, objectId, callback, wait} = params;
    let path;
    if (tableId)
        path = `${window.IasConfig.homePath}api/data/${tableId}/${objectId}/calc${(wait) ? '?wait=true' : ''}`;
    else
        path = `${window.IasConfig.homePath}zmu/${zmuTableId}/${objectId}/calc${(wait) ? '?wait=true' : ''}`;

    document.setCookies();
    fetch(path, {
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`загрузка callCalcs, result: ${result}`);
                flashMessages(result);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    window.IasLoApp.rePage(result.page);
                }
                if (callback) return callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};
//запрос пересчета (для ЗМУ)
export const callTests = async (params) => {
    let {context, zmuTableId, objectId, callback, wait} = params;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}zmu/${zmuTableId}/${objectId}/test${(wait) ? '?wait=true' : ''}`, {
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`загрузка callTests, result: ${result}`);
                flashMessages(result);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    window.IasLoApp.rePage(result.page);
                }
                if (callback) return callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};

export const copyToClipboard=(text)=> {
    if (navigator?.clipboard?.writeText) {
        // Если поддерживается, используем Clipboard API
        return navigator.clipboard.writeText(text).catch((err) => {
            console.error("Не удалось скопировать текст через Clipboard API", err);
            fallbackCopy(text); // Если ошибка, переходим к запасному методу
        });
    } else {
        // Если Clipboard API не поддерживается, используем запасной метод
        fallbackCopy(text);
    }
}

// Функция для резервного копирования текста в буфер обмена
export const fallbackCopy=(text)=> {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";  // Чтобы элемент не прокручивался с экраном
    textarea.style.opacity = "0";       // Сделаем элемент невидимым
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
        const successful = document.execCommand("copy");
        if (successful) {
            console.log("Текст успешно скопирован через резервный метод");
        } else {
            console.error("Не удалось скопировать текст через резервный метод");
        }
    } catch (err) {
        console.error("Ошибка при копировании текста через резервный метод", err);
    }

    // Удаляем временный элемент <textarea> из DOM
    document.body.removeChild(textarea);
}


