import BaseEditByMapPage from "../../../Base/BaseEditByMapPage";
import * as f from "../../../common/Funcs";
import {CloseButton, CopyButton, DeleteButton, PrintButton, SaveButton, SaveCloseButton} from "../../../common/Buttons";
import React, {Component} from "react";
import {BaseButton, BaseTextButton} from "../../../Base/BaseButtons";

const keyName = "RoutePage";
export default class RoutePage extends BaseEditByMapPage {
    static defaultProps = {
        //minWidth:'900px',
        height: '100%',
        width: '98%',
        allowNull: true
    }

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'routes_view';
        context.testType = 'route';
        context.blankType = 'empty_zmublank';
        context.idField = 'route_id';
        context.columnsCount = [{width: '20'}, {width: '80'}];
        context.topMenuButtonNames = ['print', 'reload', 'repeat', 'loadpre'];
        context.footerButtonNames = ['copy', 'approve', 'open', 'end', 'save', 'saveclose', 'delete', 'close'];
        context.fieldsSet = [
            {title: 'Параметры маршрута', fieldset: 'route', column: 0, pos: 1, block: 1},
            {title: 'Населенный пункт', fieldset: 'locale', column: 0, pos: 3},
            {title: 'Протяженность', fieldset: 'length', column: 0, pos: 2},
            {title: 'Предварительное прохождение', fieldset: 'pre_length', column: 0, pos: 3},
        ];
        context.columnsDefaults = [...context.lastUserColumns, ...[
            {column_name: 'route_id', hidden: true},
            {
                column_name: 'route_name',
                required: true,
                pos: 0,
                row: 0,
                labelMinWidth: '150px',
                labelAlign: 'left',
                disabled: (context) => {
                    return (!context.isEditRight(context)) || (context.state.data?.is_approved);
                }
            },
            {
                column_name: 'oktmo', pos: 10, row: 5, disabled: true, labelMinWidth: '150px', labelAlign: 'left',
                is_list: true,
                source: {
                    tableName: 'regions',
                    titleField: 'region_name',
                    idField: 'oktmo',
                }
            },
            {
                column_name: 'route_huntuser',
                pos: 10,
                row: 6,
                disabled: true,
                labelMinWidth: '150px',
                labelAlign: 'left',
                is_list: true,
                source: {
                    tableName: 'hunt_users',
                    titleField: 'user_name',
                    idField: 'huntuser_id',
                }
            },
            {
                column_name: 'route_land',
                pos: 10,
                row: 7,
                disabled: true,
                labelMinWidth: '150px',
                labelAlign: 'left',
                is_list: true,
                source: {
                    tableName: 'lands',
                    titleField: 'land_name',
                    idField: 'land_id',
                }
            },
            {
                column_name: 'year_on',
                disabled: true,
                pos: 10,
                row: 2,
                defaultValue: () => document.getCookie('yearFilter'),
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'year_off',
                pos: 20,
                row: 3,
                labelMinWidth: '150px',
                labelAlign: 'left',
                disabled: (context) => {
                    return (!context.isEditRight(context) || (!context.state.data?.is_approved))
                }
            },
            {
                column_name: 'is_approved',
                pos: 30,
                row: 4,
                labelMinWidth: '150px',
                labelAlign: 'left',
                disabled: true
            },
            {
                column_name: 'locality_name',
                fieldset: 'locale',
                disabled: true,
                pos: 40,
                row: 7,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'locality_distance',
                fieldset: 'locale',
                disabled: true,
                pos: 40,
                row: 8,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'locality_direction',
                fieldset: 'locale',
                disabled: true,
                pos: 40,
                row: 9,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'all',
                fieldset: 'length',
                disabled: true,
                pos: 40,
                row: 13,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'fst',
                fieldset: 'length',
                disabled: true,
                pos: 40,
                row: 10,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'fld',
                fieldset: 'length',
                disabled: true,
                pos: 40,
                row: 11,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'bog',
                fieldset: 'length',
                disabled: true,
                pos: 40,
                row: 12,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'length',
                fieldset: 'length',
                hidden: true,
                disabled: true,
                pos: 40,
                row: 13,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'pre_all',
                fieldset: 'pre_length',
                disabled: true,
                pos: 40,
                row: 13,
                labelMinWidth: '150px',
                labelAlign: 'left',

            },
            {
                column_name: 'pre_fst',
                fieldset: 'pre_length',
                disabled: true,
                pos: 40,
                row: 10,
                labelMinWidth: '150px',
                labelAlign: 'left',

            },
            {
                column_name: 'pre_fld',
                fieldset: 'pre_length',
                disabled: true,
                pos: 40,
                row: 11,
                labelMinWidth: '150px',
                labelAlign: 'left',

            },
            {
                column_name: 'pre_bog',
                fieldset: 'pre_length',
                disabled: true,
                pos: 40,
                row: 12,
                labelMinWidth: '150px',
                labelAlign: 'left',

            },
            {
                column_name: 'pre_path_upload', fieldset: 'pre_length',
                labelAlign: 'top',
                cls: 'route-gpx',
                pos: 40,
                row: 22,
            },
            {
                pos: 85,
                row: 75,
                fieldset: 'pre_length',
                column_name: 'pre_path',
                data_type: 'path',
                labelAlign: 'left',
                cls: 'route-gpx',
                path: (context) => `routes/`,
                button_label: 'трек',
                column_label: 'трек предварительного прохождения',
            },
            {column_name: 'geometry', row: 100, data_type: 'text', hidden: true}
        ]];
        //context.columnsDefaults.push();
        context.subTables = [
            {
                column: 0, block: 0,
                pos: 4,
                title: 'Тесты',
                elementName: 'TestSubPanel',
                testName: 'route',
                tableName: 'routes',
            },
            {
                column: 1,
                block: 7,
                title: 'Карта',
                elementName: 'ZmuRouteMapSub',
                tableName: 'routeslin',
                idName: 'route_id',
                // activeColumn: 'date',
                filterString: "route_id=$route_id$",
            }];
    }

    getCopyButton(context, result) {
        const b = 'copytop';
        return result.push(<CopyButton key={`${b}Button`} text={`${b}Button`} name={`${b}Button`} tooltip={'Скопировать в буфер'}
                                       func={() => context.copyRecord(context)}/>);
    }

    //кнопка экспорта в html
    addPrintButton() {
        const context = this;
        const b = 'printtop';
        const openDoc = () => {
            let filters = `?recreate=1&${f.getFilters('string')}`;
            try {
                let pasid;
                if (context.props?.parent?.grid)
                    pasid = context.props.parent.grid?.getCurrentRow().passage_id;
                window.open(`${window.IasConfig.homePath}forms/pack/${context.blankType}/doc${filters}&${(pasid) ? `pasid=${pasid}` : `routeid=${context.rowId}`}`);
            } catch {
                {
                    window.open(`${window.IasConfig.homePath}forms/pack/${context.blankType}/doc${filters}&routeid=${context.rowId}`);
                }
            }
        }
        context.topMenuButtons.push(<PrintButton key={`${b}Button`} text={`${b}Button`} name={`${b}Button`} tooltip={'Ведомость прохождения'}
                                                 func={() => {
                                                     openDoc();
                                                 }}/>);
    }

    addLoadpreButton() {
        const context = this;
        const key = 'loadprebutton';
        /*TODO: протестировать*/
        // if (!f.isAdmin()) return;
        context.topMenuButtons.push(<BaseButton name={'printButton'}
                                                text={'Использовать прошлогодний трек в качестве предварительного'}
                                                tooltip={'Загрузить трек предварительного прохождения из прохождения прошлого года'}
                                                iconCls={'fa-file'} hidden={() => false
                                                    // !context.isEditRight() && context.state?.data?.year_on < (new Date()).getFullYear()
        }
                                                key={key} func={() => f.confirm({
                                                    title: 'Действие',
                                                    message: `Вы действительно хотите загрузить трек предварительного прохождения из прохождения прошлого года?`
                                                }, (e) => {
                                                    if (e) f.callPath({
                                                        method: 'GET',
                                                        path: `zmu/routes/${(context.rowId)}/loadpre`,
                                                        callback: () => {
                                                            if (context.getData) context.getData({context});
                                                            if (context.grid) context.grid.getData({context: context.grid});
                                                        }
                                                    })
                                                })}/>);
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record, parentRow) {
        try {
            context.title = `Маршрут ${record['route_name']}`;
        } catch (e) {
        }
        return context.title;
    }

    //есть ли права на запиcь
    isEditRight() {
        const context = this;
        const state = (context.props.appViewPort?.state?.zmu?.plan_closed);
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return (!state) && f.isEditRight(rights);
    }

    //есть ли права на добавление
    isAddRight() {
        const context = this;
        const state = (context.props.appViewPort?.state?.zmu?.plan_closed);
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return (state == false) && f.isAddRight(rights);
    }

    isApproved() {
        const context = this;
        return context.state.data?.is_approved;
    }

    //есть ли права на удаление
    isDropRight() {
        const context = this;
        const state = (context.props.appViewPort?.state?.zmu?.plan_closed);
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return (state == false) && f.isDropRight(rights) && (context.state.data?.route_id);
    }

    //кнопки в подвале
    getFooterButtons(buttons) {
        const context = this;
        const result = [];
        let setDataFunc;
        const attention = () => {
            if (context.props.parent?.attention) context.props.parent.attention(context.props.parent);
        };
        buttons.map(b => {
            switch (b) {
                case 'save':
                    if ((!context.isApproved()) && context.isEditRight(context.state.rights))
                        result.push(<SaveButton key={`${b}Button`} name={`${b}Button`}
                                                func={() => {
                                                    const feature = context.hasGeom();
                                                    if (feature) {
                                                        const geotext = {
                                                            type: feature.getGeometry().getType(),
                                                            crs: {
                                                                "type": "name",
                                                                "properties": {"name": "urn:ogc:def:crs:EPSG:3857"}
                                                            },
                                                            coordinates: feature.getGeometry().getCoordinates()
                                                        };
                                                        context.elements.geometry.field.cmp.setValue(JSON.stringify(geotext));
                                                    }
                                                    context.onSave({
                                                        context,
                                                        path: `zmu/routes/${(context.rowId == null || context.rowId == -1) ? 'create' : 'update'}/`
                                                        , callback: attention
                                                    })
                                                }}/>);
                    break;
                case 'saveclose':
                    if ((!context.isApproved()) && context.isEditRight(context.state.rights))
                        result.push(<SaveCloseButton key={`${b}Button`} name={`${b}Button`} text={`${b}Button`}
                                                     func={() => {
                                                         const feature = context.hasGeom();
                                                         if (feature) {
                                                             const geotext = {
                                                                 type: feature.getGeometry().getType(),
                                                                 crs: {
                                                                     "type": "name",
                                                                     "properties": {"name": "urn:ogc:def:crs:EPSG:3857"}
                                                                 },
                                                                 coordinates: feature.getGeometry().getCoordinates()
                                                             };
                                                             context.elements.geometry.field.cmp.setValue(JSON.stringify(geotext));
                                                         }
                                                         context.onSave({
                                                             context, close: true,
                                                             path: `zmu/routes/${(context.rowId == null || context.rowId == -1) ? 'create' : 'update'}/`
                                                             , callback: attention
                                                         })
                                                     }}/>);
                    break;
                case 'close':
                    result.push(<CloseButton key={`${b}Button`} name={`${b}Button`} text={`${b}Button`}
                                             func={() => context.onCancel(context)}/>);
                    break;
                case 'delete':
                    if (context.isDropRight(context.state.rights) && context.rowId && (!context.isApproved()))
                        result.push(<DeleteButton key={`${b}Button`} text={`${b}Button`} name={`${b}Button`}
                                                  func={() => {
                                                      context.setState({features: []});
                                                      context.deleteRecord(context, `zmu/routes/delete/`);
                                                      attention();
                                                  }}/>);
                    break;
                case 'copy':
                    /*!!test*/
                    if (context.isAddRight(context.state.rights) && (context.isApproved())) {
                        let setDataFunc = (context, submit) => {
                            f.callPath({
                                id: (context.rowId),
                                path: 'zmu/routes/copy/',
                                callback: (r) => {
                                    attention();
                                    return (submit) ? submit(r) : '';
                                }
                            })
                        };
                        result.push(<CopyButton key={`${b}Button`} text={`${b}Button`} name={`${b}Button`}
                                                func={() => context.onSave({
                                                    context,
                                                    close: false,
                                                    setDataFunc
                                                })}/>);
                    }
                    break;
                case 'approve':
                    if (context.isEditRight(context.state.rights) && (context.rowId != -1) && !(context.isApproved())) {
                        setDataFunc = (context, submit) => {
                            f.callPath({
                                id: (context.rowId),
                                path: 'zmu/routes/approve/',
                                callback: (result) => {
                                    if (result.state) {
                                        context.state.data.is_approved = true;
                                        const parent = context.props.parent;
                                        // if (parent.update) parent.update(parent);
                                        // else if (parent.grid) parent.grid.getData({context: parent.grid});
                                        // context.forceUpdate();
                                        // context.onCancel(context);
                                    }
                                    return (submit) ? submit(result) : '';
                                }
                            })
                        };
                        result.push(<BaseTextButton key={`${b}Button`} text={`${b}Button`}
                            // func={() => context.copyRecord(context)}/>);
                                                    func={() => context.onSave({context, setDataFunc})}/>);
                    }
                    break;
                case 'open':
                    if ((context.props.appViewPort.state.zmu.acc_year == context.state.data.year_on) && (context.state.data?.year_off == null) && context.isEditRight(context.state.rights) && (context.isApproved())) {
                        let setDataFunc = (context, submit) => {
                            f.callPath({
                                id: (context.rowId),
                                path: 'zmu/routes/open/',
                                callback: (result) => {
                                    if (result.state) {
                                        context.state.data.is_approved = false;
                                        const parent = context.props.parent;
                                        // if (parent.update) parent.update(parent);
                                        // else if (parent.grid) parent.grid.getData({context: parent.grid});
                                        // context.forceUpdate();
                                        // context.onCancel(context);
                                    }
                                    attention();
                                    return (submit) ? submit(result) : '';
                                }
                            })
                        };
                        result.push(<BaseTextButton key={`${b}Button`} text={`${b}Button`}
                                                    func={() => context.onSave({context, setDataFunc})}/>);
                    }
                    break;
                case 'end':
                    if ((context.state.data?.year_off == null) && (context.state.data?.year_on < Number(document.getCookie('yearFilter'))) && context.isEditRight(context.state.rights) && (context.isApproved())) {
                        let setDataFunc = (context, submit) => {
                            let values = new FormData();
                            values.append('year_off', Number(document.getCookie('yearFilter')) - 1);
                            f.setObjectData({
                                context: context,
                                id: (context.rowId),
                                path: 'zmu/routes/update/',
                                values: values,
                                callback: (result) => {
                                    if (result.state) {
                                        context.state.data.is_approved = false;
                                        const parent = context.props.parent;
                                        // if (parent.update) parent.update(parent);
                                        // else if (parent.grid) parent.grid.getData({context: parent.grid});
                                        // context.forceUpdate();
                                        // context.onCancel(context);
                                    }
                                    attention();
                                    return (submit) ? submit(result) : '';
                                }
                            })
                        };
                        result.push(<BaseTextButton key={`${b}Button`} text={`${b}Button`}
                                                    func={() => context.onSave({context, setDataFunc})}/>);
                    }
                    break;
            }
        });
        return result;
    }

    getData(params) {
        super.getData(params);
        if (params.context.elements.ZmuRouteMapSub) params.context.elements.ZmuRouteMapSub.getData({context: params.context.elements.ZmuRouteMapSub});
        // context.
    }

}


